import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';



// Components
import MobileMenu from '../components/hud/MobileMenu';
import MobileNav from '../components/hud/MobileNav';



// Routers
import AuthRouter from './AuthRouter';



// Custom hooks
import { ScrollToTop } from '../hooks/ScrollToTop';
import { useWindowDimensions } from '../hooks/useDimensions';



const AppRouter = () => {

   useWindowDimensions();

   const { width } = useSelector(state => state.ui);

   return (
      <div className='body'>
         <Router>
            <ScrollToTop />

            <AuthRouter />

            { width <= 992 && ( <MobileNav /> ) }

            { width <= 992 && ( <MobileMenu /> ) }
         </Router>
      </div>
   );
}



export default AppRouter;
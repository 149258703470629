import React from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';
// import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';



// store
import { store } from './store/store';



// Components
import PersistentLog from './components/auth/PersistentLog';



// Routers
import AppRouter from './routers/AppRouter';
// import { Maintenance } from './views/Maintenance';



// Google analytics
ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID_BS, {
   // testMode: process.env.NODE_ENV === 'production' ? false : true
});
ReactGA.gtag('config', process.env.REACT_APP_GA_MEASUREMENT_ID_FS);

// Google tag manager
// TagManager.initialize({
//    gtmId: process.env.REACT_APP_GTM_ID
// });

// Facebook pixel
ReactPixel.init(process.env.REACT_APP_PIXEL_ID_BS, {}, {
   autoConfig: true,
   debug: false,
});
ReactPixel.init(process.env.REACT_APP_PIXEL_ID_FS, {}, {
   autoConfig: true,
   debug: false,
});

ReactPixel.pageView();



const App = () => {

   return (
      <Provider store={store}>
         <PersistentLog>
            <AppRouter />
         </PersistentLog>
      </Provider>
      // <Maintenance />
   );
}



export default App;
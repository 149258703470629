import { stringCapitalize } from '../helpers/format';

export const types = {
   SET_PRODUCT: '[PRODUCT] set product data by slug',
   SET_LOADING: '[PRODUCT] set loading state',
   SET_COMPARISON: '[PRODUCT] set comparison elements',
}



const initialState = {
   loading: true,
   // product data
   id: null,
   name: null,
   images: [],
   description: null,
   categoryId: null,
   price: null,
   offer: null,
   sku: null,
   attributes: [],
   brand: null,
   offerData: {
      limit: 0,
      simbol: '',
      name: '',
   },
   perks: [],
   details: [],
   paymentMethods: [],
   ownStock: null,
   tags: [],
   comparison: [],
   loadingComparison: true
}



export const productReducer = (state = initialState, action) => {
   switch (action.type) {
      case types.SET_PRODUCT:
         return {
            ...state,
            id: action.payload.id,
            name: action.payload.name,
            images: action.payload.images,
            description: action.payload.description,
            categoryId: action.payload.categoryId,
            price: action.payload.price,
            offer: action.payload.offer,
            sku: action.payload.sku,
            attributes: action.payload.attributes,
            brand: action.payload.brand,
            offerData: action.payload.offerData,
            perks: action.payload.perks,
            details: action.payload.details,
            paymentMethods: action.payload.paymentMethods,
            ownStock: action.payload.ownStock,
            tags: action.payload.tags
         }

      case types.SET_LOADING: {
         const { key, value } = action.payload;

         const keyValue = key.length > 0 ? stringCapitalize(key) : key;

         return {
            ...state,
            [`loading${keyValue}`]: value
         }
      }

      case types.SET_COMPARISON:
         return {
            ...state,
            comparison: action.payload
         }

      default:
         return state;
   }
}
import React from 'react';
import ReactDOM from 'react-dom';



import App from './App';



import './styles/index.css';

// Slick styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// if (process.env.NODE_ENV === 'production') {
//   console.log = function() {}
// }

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
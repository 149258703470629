import ReactGA from 'react-ga4';



/**
 * Evento para llevar el seguimiento de acciones de llamada en google analytics
 * @returns {void}
 */
export const analyticsPhoneCall = () => {
   // ReactGA.event({
   //    action: 'clic',
   //    category: 'llamada',
   //    label: 'llamadas'
   // });
}

/**
 * Evento para llevar el seguimiento de acciones de whatsapp en google analytics
 * @returns {void}
 */
export const analyticsWhatsapp = () => {
   // ReactGA.event({
   //    action: 'clic',
   //    category: 'wpp',
   //    label: 'boton_de_whatsapp'
   // });
}

/**
 * Evento para llevar el seguimiento de acciones del chat en google analytics
 * @returns {void}
 */
export const analyticsChat = () => {
   // ReactGA.event({
   //    action: 'clic',
   //    category: 'freshdeck',
   //    label: 'freshdesk',
   //    value: 10
   // });
}

/**
 * Evento para llevar el seguimiento de acciones de generación de pedidos en google analytics
 * @param {number} pedidoId Id del pedido al que se le quiere hacer seguimiento
 * @returns {void}
 */
export const analyticsOrderGenerated = (pedidoId) => {
   ReactGA.gtag('event', 'conversion', {
      send_to: `${process.env.REACT_APP_GA_MEASUREMENT_ID_BS}/kHI_CNm2rKEYEJfKhO0C`,
      transaction_id: pedidoId
   });
   ReactGA.gtag('event', 'conversion', {
      send_to: `${process.env.REACT_APP_GA_MEASUREMENT_ID_FS}/wdICP7GrKEYEOm5hO0C`,
      transaction_id: pedidoId
   });
}

/**
 * Evento para llevar el seguimiento de los pedidos que fueron pagados en la web
 * @param {number} transaction_id Id del pedido que fue pagado
 * @param {number} value Monto total del pedido
 * @param {string} currency Moneda usada en el pedido
 * @param {Array<{item_id:string,item_name:string}>} items Listado de productos asociados al pedido
 * @returns {void}
 */
export const analyticsOrderPurchased = (transaction_id, value, currency, items) => {
   // Si .ga no funciona usar .gtag
   // ReactGA.gtag('event', 'purchase', { transaction_id, value, currency, items });
}
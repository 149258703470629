import { types } from "../reducers/searchReducer";
import { request, getResults, apiURL } from "../helpers";



// Actions

export const setResults = (results = [], total, pages) => ({
   type: types.SET_RESULTS,
   payload: { results, total, pages }
});

export const startGetResults = (search, page, rFilters = {}, order, priceFilter) => {
   return async (dispatch, getState) => {
      dispatch(setLoading(true));
      
      try {
         const { location } = getState().ui;

         const response = await request({
            location: '/bridgestone/v1',
            path: `/producto/buscar?page=${page}`,
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  busqueda: search,
                  filtros: [rFilters],
                  filtro_precio: {
                     desde: priceFilter.price_from,
                     hasta: priceFilter.price_upto
                  },
                  ordenar: order,
                  comuna_id: location?.communeId ?? undefined
               }],
               incluir_especificaciones: true,
               limit: 12,
               offset: (page - 1) * 12
            }
         });
         
         const { results, total, pages } = getResults(response.data);

         dispatch(setResults(results, total, pages));
      } catch (error) {
         console.log(error);
      }
      
      dispatch(setLoading(false));
   }
}

export const setLoading = (state) => ({
   type: types.SET_LOADING,
   payload: state
});

export const setAttribute = (key, values) => ({
   type: types.SET_ATTRIBUTE,
   payload: { key, values }
});

export const startGetAttribute = (attrId, key, initLoading = false, endLoading = false) => {
   return async (dispatch) => {
      initLoading && dispatch(setLoadingAttr(true));

      try {
         const response = await request({
            path: '/clave-atributos/valores',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  busqueda: [attrId]
               }]
            }
         });

         const datos = response.data.datos.claves_valores[0].neu_valor_atributos;

         const values = datos.map(attr => ({ text: attr.nombre, value: attr.valor, id: attr.id }));

         dispatch(setAttribute(key, values));
      } catch (error) {
         console.log(error);

         // const errors = error?.response?.data?.errores;

         // console.log(errors);
      }
      
      endLoading && dispatch(setLoadingAttr(false));
   }
}

export const startGetDependentAttribute = (key, dependencies) => {
   return async (dispatch) => {
      dispatch(setLoadingAttr(true));

      dependencies = Object.entries(dependencies).map(([key, value]) => [key, [value]]);

      dependencies = Object.fromEntries(dependencies);

      try {
         const response = await request({
            location: '/bridgestone/v1',
            path: '/producto/buscar-atributos-unicos',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  filtros: [dependencies],
                  atributos_buscados: [key]
               }]
            }
         });

         const data = response.data.datos.atributos_posibles[key];

         if (data) {
            const values = data.map(attr => ({ text: attr.valor, value: attr.valor, id: attr.atributo_valor_id }));
   
            dispatch(setAttribute(`${key}s`, values));
         }

      } catch (error) {
         console.log(error);
      }
      
      dispatch(setLoadingAttr(false));
   }
}

export const setLoadingAttr = (state) => ({
   type: types.SET_ATTRIBUTES_LOADING,
   payload: state
});

export const setSuggestions = (suggestions) => ({
   type: types.SET_SUGGESTIONS,
   payload: suggestions
});

export const startGetSuggestions = (search) => {
   return async dispatch => {
      try {
         const response = await request({
            location: '/bridgestone/v1',
            path: `/producto/busqueda/sugerencia?busqueda=${search}`,
            headers: {
               'Content-Type': 'application/json'
            }
         });

         const data = response.data.datos.productos_sugerencia;

         const suggestions = data.map(p => ({
            id: p.id,
            name: p.nombre,
            price: p.precio_oferta !== null ? p.precio_oferta : p.precio_final,
            uri: p.url,
            sku: (() => {
               if (p.sku_a_mostrar.includes('(')) {
                  const splitted = p.sku_a_mostrar.split('(');
         
                  return splitted[0];
               }
         
               return p.sku_a_mostrar;
            })(),
            image: p.neu_producto_imagenes.length > 0
               ? `${apiURL}/storage/${p.neu_producto_imagenes[0].imagen}`
               : `${process.env.PUBLIC_URL}/assets/images/no-image/product.png`
         }));
         
         dispatch(setSuggestions(suggestions));
      } catch (error) {
         console.log(error);
      }
   }
}

// Filters

export const setFilters = (filters) => ({
   type: types.SET_FILTERS,
   payload: filters
});

export const startGetFilters = (search) => {
   return async dispatch => {
      dispatch(setLoadingFilters(true));
      
      try {
         const response = await request({
            location: '/bridgestone/v1',
            path: '/producto/obtener-filtros',
            method: 'POST',
            headers: {
               'Content-Type': 'application/json'
            },
            body: {
               datos: [{
                  busqueda: search
               }]
            }
         });

         const { atributos } = response.data.datos;

         const filters = Object.entries(atributos)
            .map(([key, values]) => ({
               title: key.replaceAll('_', ' '),
               options: values.map(val => ({
                  id: val.atributo_valor_id,
                  value: val.valor,
                  text: !!val.simbolo ? `${val.valor} ${val.simbolo}` : val.valor
               }))
            }));

         dispatch(setFilters(filters));
      } catch (error) {
         console.log(error);
      }
      
      dispatch(setLoadingFilters(false));
   }
}

export const setLoadingFilters = (state) => ({
   type: types.SET_FILTERS_LOADING,
   payload: state
});

export const toggleFiltersModal = () => ({
   type: types.SET_FILTERS_MODAL
});

export const toggleOrderingModal = () => ({
   type: types.SET_ORDERING_MODAL
});
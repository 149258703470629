import React, { lazy } from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';



// Components
import Contact from '../components/hud/Contact';
import Footer from '../components/ui/Footer';
import HeaderGroup from '../components/ui/HeaderGroup';
import SearchBar from '../components/ui/SearchBar';
import LoadingLazy from '../components/ui/LoadingLazy';



// Routers
const ProfileRouter = lazy(() => import('./ProfileRouter'));
const WarrantiesRouter = lazy(() => import('./WarrantiesRouter'));



// Lazy views
const Brand = lazy(() => import('../views/Brand'));
const BrandsSection = lazy(() => import('../views/BrandsSection'));
const Cart = lazy(() => import('../views/Cart'));
const CheckOut = lazy(() => import('../views/CheckOut'));
const EventData = lazy(() => import('../views/EventData'));
const Faq = lazy(() => import('../views/Faq'));
const GeneratedOrder = lazy(() => import('../views/GeneratedOrder'));
const Main = lazy(() => import('../views/Main'));
const Offers = lazy(() => import('../views/Offers'));
const Offices = lazy(() => import('../views/Offices'));
const OrderConfirmation = lazy(() => import('../views/OrderConfirmation'));
const OrderTracking = lazy(() => import('../views/OrderTracking'));
const Product = lazy(() => import('../views/Product'));
const SearchResults = lazy(() => import('../views/SearchResults'));
const Tac = lazy(() => import('../views/Tac'));



const MainRouter = () => {

   const { width } = useSelector(state => state.ui);

   return (
      <>
         <HeaderGroup />

         { width <= 992 && ( <SearchBar /> ) }

         <Contact />

         <Routes>
            <Route path='/' element={<LoadingLazy element={<Main />} />} />

            <Route path='/carrito' element={<LoadingLazy element={<Cart />} />} />

            <Route path='/preguntas-frecuentes' element={<LoadingLazy element={<Faq />} />} />

            <Route path='/terminos-y-condiciones' element={<LoadingLazy element={<Tac />} />} />

            {/* <Route path='/marcas' element={<LoadingLazy element={<BrandsSection />} />} /> */}

            <Route path='/marcas/:slug' element={<LoadingLazy element={<Brand />} />} />

            <Route path='/checkout' element={<LoadingLazy element={<CheckOut />} />} />

            <Route path='/checkout/exito' element={<LoadingLazy element={<GeneratedOrder />} />} />

            <Route path='/checkout/validacion/:paymentId' element={<LoadingLazy element={<OrderConfirmation />} />} />

            <Route path='/seguimiento-de-orden/*' element={<LoadingLazy element={<OrderTracking />} />} />

            <Route path='/producto/:slug/*' element={<LoadingLazy element={<Product />} />} />
            
            <Route path='/s' element={<LoadingLazy element={<SearchResults />} />} />

            <Route path='/perfil/*' element={<LoadingLazy element={<ProfileRouter />} />} />

            <Route path='/sucursales-aliados' element={<LoadingLazy element={<Offices />} />} />

            <Route path='/ofertas' element={<LoadingLazy element={<Offers />} />} />

            <Route path='/evento/*' element={<LoadingLazy element={<EventData />} />} />

            <Route path='/garantias/*' element={<LoadingLazy element={<WarrantiesRouter />} />} />
            
            <Route path='*' element={<Navigate to='/' replace />} />
         </Routes>

         <Footer />
      </>
   );
}



export default MainRouter;
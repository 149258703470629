


// Helpers
import { analyticsWhatsapp } from './google-analytics';
import { pixelContact } from './meta-pixel';



/**
 * Teléfono de whatsapp de contacto
 */
export const whatsapp = '56973949038';

/**
 * Link de whatsapp de contacto
 */
export const whatsappLink = `https://wa.me/${whatsapp}`;

/**
 * Texto a mostrar en los contactos de whatsapp
 */
export const whatsappShow = '+56 9 7394 9038';

/**
 * Teléfono de contacto
 */
export const phoneNumber = '+56232411850';

/**
 * Text a mostrar en el teléfono de contacto
 */
export const phoneNumberShow = '+56 2 3241 1850';

/**
 * Email de contacto
 */
export const contactEmail = 'contacto@neumaticobridgestone.cl';



/**
 * Método para contactar a un agente a través de whatsapp cuando el producto
 * no tenga stock disponible
 * @param {string} link enlace del producto
 */
export const noStockContact = (link) => {
   const text = `Hola, buen día. Estoy interesado en este producto: ${link}`;
   
   const uri = `${whatsappLink}?text=${encodeURIComponent(text)}`;

   window.open(uri, '_blank');

   analyticsWhatsapp();
   pixelContact('Whatsapp', `+${whatsapp}`);
}

/**
 * Método para contactar a un agente a través de whatsapp cuando un pedido no pueda
 * ser agendado debido a que el punto de entrega no posee agenda
 * @param {number} orderId Id de la orden que se desea coordinar
 */
export const noScheduleContact = (orderId) => {
   const text = `Hola, buen día. Quiero coordinar el despacho del pedido: ${orderId}`;
   
   const uri = `${whatsappLink}?text=${encodeURIComponent(text)}`;

   window.open(uri, '_blank');

   analyticsWhatsapp();
   pixelContact('Whatsapp', `+${whatsapp}`);
}

/**
 * Método para contactar a un agente sobre un producto dado su URI
 * @param {string} productUri URI del producto por el que se quiere contactar a un agente
 */
export const productContact = (productUri) => {
   const text = `Hola, buen día. Estoy interesado en este producto: ${productUri}`;
   
   const uri = `${whatsappLink}?text=${encodeURIComponent(text)}`;

   window.open(uri, '_blank');

   analyticsWhatsapp();
   pixelContact('Whatsapp', `+${whatsapp}`);
}